<style scoped>
#index {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  padding: 10px;
}
.el-main {
  position: relative;
  padding: 0;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.grid-item {
  width: fit-content;
  max-width: calc(33.33% - 5px);
  margin-bottom: 5px;
  position: relative;
  border-radius: 10px;
}
.grid-item .info {
  position: absolute;
  bottom: 0;
  z-index: 9;
  left: 0;
  background: #000;
  right: 0;
  color: #fff;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 0 0 10px 10px;
}
.grid-item .info .title {
  margin-bottom: 10px;
}
.grid-item .video {
  width: 300px;
  min-height: 400px;
  height: auto;
  border-radius: 10px;
}
</style>
<template>
  <el-container>
    <el-main>
      <Header @handle-search="handleSearch" />
      <el-container
        id="index"
        v-infinite-scroll="fetchInfo"
        infinite-scroll-delay="500"
        infinite-scroll-disabled="disabled"
      >
        <div class="grid infinite-list">
          <div class="grid-item" v-for="video in videos" :key="video.clientId">
            <video
              @mouseover="playVideo($event)"
              @mouseleave="pauseVideo($event)"
              class="video"
              controls
              muted="muted"
              controlslist="nodownload nofullscreen noremoteplayback noplaybackrate"
              @click="goToPlayPage(video)"
            >
              <source :src="video.video_url" />
              您的浏览器不支持 HTML5 video 标签。
            </video>
            <div class="info">
              <div class="title">{{ video.title }}</div>
              <div class="others">
                <div class="client">
                  <el-link type="info" @click="goToClientPage(video.clientId)"
                    >@{{ video.clientName }}</el-link
                  >
                </div>
                <div class="date"></div>
              </div>
            </div>
          </div>
        </div>
      </el-container>
    </el-main>
  </el-container>
</template>
<script>
import Masonry from "../js/masonry.pkgd";
import Header from "../components/Header.vue";

export default {
  components: { Header },
  name: "App",
  data() {
    return {
      videos: [],
      total: 0,
      page: 1,
    };
  },
  computed: {
    disabled() {
      return this.total / 20 <= this.page;
    },
  },
  mounted() {
    this.fetchInfo(this.$route.query.clientId);
  },
  methods: {
    playVideo(event) {
      event.target.play();
    },
    pauseVideo(event) {
      event.target.pause();
    },
    async fetchDataByMarker(id) {
      const response = await fetch(
        `/ShuKeManage/SignController/getUserArticleList?type=1&clientId=${id}`
      );
      const jsonData = await response.json();
      console.log(jsonData);
      this.otherList = jsonData.data.video_json;
    },
    goToClientPage(id) {
      this.$router.push({
        path: "/client",
        query: { clientId: id },
      });
    },
    goToPlayPage(item) {
      this.$router.push({
        path: "/play",
        query: { item },
      });
    },
    handleSearch(content) {
      if (content === "") {
        return;
      }
      this.$router.push({
        path: "/search",
        query: { searchContent: content, searchType: "view" },
      });
    },
    async fetchInfo(clientId) {
      if (this.total / 20 > this.page) {
        this.page += 1;
      }
      const response = await fetch(
        `/ShuKeManage/SignController/getjckdnr?page=${
          this.page
        }&rows=20&type=1${clientId ? `&clientId=${clientId}` : ""}`
      );
      const jsonData = await response.json();
      console.log(jsonData);
      this.total = jsonData.total;
      this.videos = [...this.videos, ...jsonData.video_json];
      setTimeout(() => {
        /* eslint-disable */
        const msnry = new Masonry(".grid", {
          itemSelector: ".grid-item",
          columnWidth: ".grid-item",
          gutter: 5,
          fitWidth: true,
        });
        msnry.layout();
        // setTimeout(() => msnry.layout(), 3000);
      }, 300);
    },
  },
};
</script>
